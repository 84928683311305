let dataFormater = {
  formatMoney(value) {
    return value.toLocaleString("fr-FR", {
      style: "currency",
      currency: "EUR",
    });
  },
  formatPercentage(value) {
    return Math.round(value * 100) / 100 + "%";
  },
};

export default dataFormater;
